<template>
  <div>
    <b-row>
        <b-col class="col-12 col-md-3 mt-1 mt-md-0">
            <b-media no-body>
                <b-link>
                <b-img
                    v-if="avatar"
                    ref="previewEl"
                    rounded
                    :src="avatar"
                    height="200"
                />
                <!-- <feather-icon
                    v-else
                    icon="CodesandboxIcon"
                    size="200"
                /> -->

                <div v-else style="
                        height:200px;
                        width:200px;
                        border: 2px white dashed;
                        border-radius: 5px;"
                        class="d-flex justify-content-center align-items-center">
                    <span class="align-middle">Company Logo</span>
                </div>
                </b-link>
            </b-media>

            <div class="mt-2">
            <!-- upload button -->
            <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class="mb-75 mr-75"
            >
            Upload
            </b-button>
            <b-form-file
            ref="refInputEl"
            v-model="avatar"
            accept=".jpg, .png, .gif"
            :hidden="true"
            plain
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            size="sm"
            class="mb-75 mr-75"
            >
            Reset
            </b-button>
            <!--/ reset -->
            <b-card-text>Allowed JPG, GIF or PNG. Max size of 800kB</b-card-text>
            </div>
        </b-col>
        <b-col class="col col-12 col-md-9 mt-1 mt-md-0">
            <b-card>
                <!-- User Info: Input Fields -->
                <b-form>
                <b-row>

                    <!-- Field: Name -->
                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Name"
                        label-for="name"
                    >
                        <b-form-input
                        id="name"
                        v-model="company.name"
                        />
                    </b-form-group>
                    </b-col>

                    <!-- Field: Email -->
                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Email"
                        label-for="email"
                    >
                        <b-form-input
                        id="email"
                        v-model="company.email"
                        type="email"
                        />
                    </b-form-group>
                    </b-col>

                    <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Country"
                        label-for="country"
                    >
                        <b-form-input
                        id="country"
                        v-model="company.country"
                        type="text"
                        />
                    </b-form-group>
                    </b-col>

                   <b-input-group>
                    <b-input-group-prepend is-text>
                        US (+1)
                    </b-input-group-prepend>
                    <cleave
                        id="phone"
                        v-model="company.phone"
                        class="form-control"
                        :raw="false"
                        :options="options.phone"
                        placeholder="(555) 555-5555"
                    />
                    </b-input-group>

                    <!-- Field: Status -->
                    <!-- <b-col
                    cols="12"
                    md="4"
                    >
                    <b-form-group
                        label="Status"
                        label-for="user-status"
                    >
                        <v-select
                        v-model="userData.status"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="statusOptions"
                        :reduce="val => val.value"
                        :clearable="false"
                        input-id="user-status"
                        />
                    </b-form-group>
                    </b-col> -->
                </b-row>
                </b-form>
            </b-card>
        </b-col>
    </b-row>

    

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Save Changes
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
    >
      Reset
    </b-button>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BMediaAside, 
  BMediaBody,
  BLink, 
  BImg,
  BFormFile,
  BCardText,
  BInputGroupPrepend, 
  BInputGroup
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import Cleave from 'vue-cleave-component'
import 'cleave.js/dist/addons/cleave-phone.us'

export default {
  components: {
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    BMediaAside, 
    BMediaBody,
    BLink, 
    BImg,
    BFormFile,
    BCardText,
    BInputGroupPrepend, 
    BInputGroup,
    Cleave
  },
  props: {
  },
  directives: {
    Ripple,
  },
  data(){
    return {
        company:{
            name:"",
            email:"",
            country:"",
            phone:"",
        },
        userData:{
            id: 1,
            fullName: 'Galen Slixby',
            company: 'Yotz PVT LTD',
            role: 'editor',
            username: 'gslixby0',
            country: 'El Salvador',
            contact: '(479) 232-9151',
            email: 'gslixby0@abc.net.au',
            currentPlan: 'enterprise',
            status: 'inactive',
        },
        avatar: null,
        profileFile: null,
        refInputEl:null,
        options:{
            phone: {
                numericOnly: true,
                blocks: [0, 3, 3, 4],
                delimiters: ["(", ") ", "-"]
            },
        }
    }
  },
  methods:{
    inputImageRenderer(){
        useInputImageRenderer(refInputEl, base64 => {
            // eslint-disable-next-line no-param-reassign
            this.avatar = base64
        })
    }
  },
//   setup(props) {
//     const roleOptions = [
//       { label: 'Admin', value: 'admin' },
//       { label: 'Author', value: 'author' },
//       { label: 'Editor', value: 'editor' },
//       { label: 'Maintainer', value: 'maintainer' },
//       { label: 'Subscriber', value: 'subscriber' },
//     ]
//     const statusOptions = [
//       { label: 'Pending', value: 'pending' },
//       { label: 'Active', value: 'active' },
//       { label: 'Inactive', value: 'inactive' },
//     ]
//     const permissionsData = [
//       {
//         module: 'Admin',
//         read: true,
//         write: false,
//         create: false,
//         delete: false,
//       },
//       {
//         module: 'Staff',
//         read: false,
//         write: true,
//         create: false,
//         delete: false,
//       },
//       {
//         module: 'Author',
//         read: true,
//         write: false,
//         create: true,
//         delete: false,
//       },
//       {
//         module: 'Contributor',
//         read: false,
//         write: false,
//         create: false,
//         delete: false,
//       },
//       {
//         module: 'User',
//         read: false,
//         write: false,
//         create: false,
//         delete: true,
//       },
//     ]

//     // const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
//     //   // eslint-disable-next-line no-param-reassign
//     //   getCurrentInstance().data.key.avatar = base64

//     // })
    


//     return {
//       avatarText,
//       roleOptions,
//       statusOptions,
//       permissionsData,
      
//     }
//   },
}
</script>

<style lang="scss">
@import '~@core/scss/vue/libs/vue-select.scss';
</style>